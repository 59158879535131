import { createContext, useContext, ReactNode, useEffect, useState } from 'react';

type Theme = 'dark' | 'light';

interface ThemeContextProps {
  theme: Theme;
  setTheme: React.Dispatch<React.SetStateAction<Theme>>;
}

const ThemeContext = createContext<ThemeContextProps | undefined>(undefined);

export function ThemeProvider({ children }: { children: ReactNode }) {
    const [theme, setTheme] = useState<Theme>(() => {
        const storedTheme = sessionStorage.getItem('theme');
        if (storedTheme) {
            return storedTheme as Theme;
        } else {
            const prefersDarkMode = window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches;
            return prefersDarkMode ? 'dark' : 'light';
        }
    });

    useEffect(() => {
        const root = window.document.documentElement;
        root.classList.remove('dark', 'light');
        root.classList.add(theme);
        sessionStorage.setItem('theme', theme);
    }, [theme]);

    const contextValue: ThemeContextProps = {
        theme,
        setTheme,
    };

    return (
        <ThemeContext.Provider value={contextValue}>{children}</ThemeContext.Provider>
    );
}

export function useTheme() {
    const context = useContext(ThemeContext);
    if (!context) {
        throw new Error('useTheme must be used within a ThemeProvider');
    }
    return context;
}
