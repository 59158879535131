/* eslint-disable react/prop-types */
import ListCards from 'components/cards/list-cards';
import Patches from 'data/patches/all-patches.json';
import { PatchWithBasicInfo } from 'types/patches.type';
import { CardsList } from 'types/cards.type';
import { useLocation } from 'react-router-dom';
import { ProductWithBasicInfo } from 'types/packages.type';

const RelatedPck: React.FC<any> = ({packagesInfo}: {packagesInfo: ProductWithBasicInfo}) => {


    const location = useLocation();
    const versionInQueryParameter = new URLSearchParams(location.search).get('version');

    const findAllPatches = () => {
        return Patches
            .filter((patch: PatchWithBasicInfo) => patch.productId === packagesInfo.productId)
            .filter((patch: PatchWithBasicInfo) => {
                if (versionInQueryParameter && versionInQueryParameter.length > 0) {
                    return patch.versions.includes(versionInQueryParameter);
                } else {
                    return patch.versions.includes(packagesInfo.latestVersion);
                }
            })
            .map((patch: PatchWithBasicInfo) => patch.patchId);
    };
    

    const pckList: CardsList = {
        'products': packagesInfo.relatedToProductId ,
        'patches': findAllPatches(),
    };

    return (
        <>
            {
                packagesInfo && packagesInfo.relatedToProductId.length > 0 && (

                    <>
                        <h3 className='mb-5 border-t pt-5 font-title capitalize font-bold'>Related Downloads</h3>
                        <ListCards
                            className='grid grid-cols-1 md:grid-cols-2 md:gap-6 lg:grid-cols-3 lg:gap-6'
                            sortFunction={'alphabetical'}
                            view={'pck'}
                            pckList={pckList}
                        />
                    </>

                )
            }
        </>
    )
}

export default RelatedPck;