'use client';
import { useDrawer, type DrawerPlacements } from 'components/drawer-views/use-drawer';
import { Button } from 'components/ui/button';
import cn from 'utils/class-names';
import FaSliders from 'assets/fa-sliders-icon';

interface FiltersButtonProps {
  className?: string;
  modalView: React.ReactNode;
  placement: DrawerPlacements;
  overlay: any;
  isMobileOnly: boolean;
}

export function FiltersButton({
    className,
    placement,
    modalView,
    overlay,
    isMobileOnly,
}: FiltersButtonProps) {
    const { openDrawer } = useDrawer();
    return (
        <Button
            className={cn(
                'cursor-pointer @lg:mt-0 @lg:w-auto ',
                className,
                {'hidden sm:block': isMobileOnly}
            )}
            onClick={() =>
                openDrawer({
                    view: modalView,
                    placement,
                    overlay: overlay
                })
            }
            size='sm'
            aria-label='Filter Packages'
            name='button filter'
        >
            <FaSliders themeColor='dark:text-blue-dark text-white'/>
        </Button>
    );
}
