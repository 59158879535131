import { Dropdown } from 'components/ui/dropdown';
import { Button } from 'components/ui/button';
import FaChevronDown from 'assets/fa-chevron-down';
import { useEffect, useState } from 'react';

interface DropDownProps {
    queryName: string;
    className?: string;
    allElementsInDropdown: Array<any>;
    sendDataToParent?: any;
}

export function DropDown({queryName, allElementsInDropdown, sendDataToParent }: DropDownProps) {

    const handleSelectionChange = (selectedItem: string) => {
        sendDataToParent(selectedItem);
    };
    const [elementToBeDisplayed, setElementToBeDisplayed] = useState('');
    


    const switchElementFromDropdown = (selectorValue: string) => {
        setElementToBeDisplayed(selectorValue);
        handleSelectionChange(selectorValue);
    };

    useEffect(() => {
        const queryParameter = new URLSearchParams(location.search).get(queryName);
        const elementToBeDisplayedInDropdown = (queryParameter && queryParameter.toString().toLocaleLowerCase().includes('latest') || !queryParameter) ? `${allElementsInDropdown[0][queryName]}` : queryParameter;
        setElementToBeDisplayed(elementToBeDisplayedInDropdown);
    }, [allElementsInDropdown]);

    return (
        <Dropdown placement='bottom-end'>
            <Dropdown.Trigger aria-label='button' role='button' id='btn-sort-cards' aria-sort='none' aria-expanded='false' aria-controls='issue-26732-body issue-26732-comments issue-26732-reactions issue-26732-reactions-menu'>
                <Button variant='outline' className='text-white bg-blue-dark dark:text-blue-dark dark:bg-white hover:bg-blue-dark dark:hover:bg-white hover:text-white' id='dropdownTrigger' aria-label='trigger sorting dropdown'>
                    {elementToBeDisplayed} {elementToBeDisplayed === allElementsInDropdown[0][queryName] && '(latest)'}
                    <FaChevronDown className='ml-2' themeColor='dark:text-blue-light text-white' />
                </Button>
            </Dropdown.Trigger>
            <Dropdown.Menu className='divide-y bg-white dark:bg-blue-dark'>
                <div className='mb-2 dark:text-white'>
                    {allElementsInDropdown.map((info: any, index: number) => (
                        <Dropdown.Item key={index} onClick={() => switchElementFromDropdown(info[queryName])} className='text-left'>
                            {info[queryName]} {info[queryName] === allElementsInDropdown[0][queryName] && '(latest)'}
                        </Dropdown.Item>
                    ))}
                </div>
            </Dropdown.Menu>
        </Dropdown>
    )
}
