import React, { lazy, Suspense, useEffect, useState } from 'react';
import { SearchResults } from 'pages/search-results';
import LogoComponent from 'assets/logo';
import useDarkSide from 'hooks/use-dark-theme';
import FixBtnOnScrollDown from 'components/ui/fix-button-on-scroll-down';
import { Link } from 'components/link';
import { useLocation, useSearchParams } from 'react-router-dom';
import ScrollToTop from 'hooks/scroll-to-top';

const GlobalDrawer = lazy(() => import('components/drawer-views/container'));
const Header = lazy(() => import('layouts/menus/header'));
const Footer = lazy(() => import('layouts/footer'));

const renderLoader = () => <p></p>;
const renderLoaderHeader = () => (
    <div className='sticky top-0 z-50 grid grid-cols-12 bg-white text-blue-light dark:bg-blue-dark dark:text-white'>
        <div className='col-start-2 col-span-10 xl:col-start-3 xl:col-span-8'>
            <header className='sticky top-0 z-50 flex items-center justify-between bg-white dark:bg-blue-dark dark:text-white'>
                <div className='hidden items-center gap-3 xl:flex'>
                    <Link
                        label='Site Logo'
                        href='/'
                        className='me-4 hidden w-[155px] shrink-0 lg:me-5 xl:block text-left'
                    >
                        <div>
                            <LogoComponent
                                fillColorLogo='dark:text-white text-blue-sentry'
                                fillColorText='dark:text-white'
                                strokeWidth={0.5} size='125px'
                                font='font-font-title'
                            />
                        </div>
                    </Link>
                </div>
            </header>
        </div>
    </div>
);

export const ApplicationLayout = ({
    children,
} : {
    children: React.ReactNode;
  }) => {
    const [colorTheme] = useDarkSide();

    const [searchValue, setSearchValue] = useState('');
    const [searchState, setSearchState] = useState(false);
    const [searchParams, setSearchParams] = useState('');
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [searchParamsRouter, setSearchParamsRouter] = useSearchParams();
    const location = useLocation();

    const handleChildExpand = (isExpanded: boolean) => {
        setSearchState(isExpanded);
    };

    const handleChildSearchValue = (searchString: string) => {
        setSearchValue(searchString);
    };

    useEffect(() => {

        const queryParams = new URLSearchParams(location.search);
        const paramValue = queryParams.get('search');

        if (paramValue) {
            setSearchParams(paramValue);
            setSearchState(true);
            setSearchValue(paramValue);
        } else {
            setSearchParams('');
            setSearchParamsRouter((params) => {
                params.delete('search');
                return params;
            });
        }

    }, [searchValue])
 
    return (
        <div className={`theme-${colorTheme}`}>
            <ScrollToTop />
            <main className='min-h-screen bg-blue-lighter dark:bg-blue-light dark:text-white'>
                <Suspense fallback={renderLoaderHeader()}>
                    <div id='top-menu' className='grid sticky top-0 z-50 grid-cols-12 bg-blue-dark text-blue-light dark:bg-blue-dark dark:text-white'>
                        <div className='col-start-2 col-span-10 xl:col-start-3 xl:col-span-8'>
                            <Suspense fallback={renderLoader()}>
                                <Header updateSearchValue={handleChildSearchValue} sendDataToParent={handleChildExpand} />
                            </Suspense>
                        </div>
                    </div>
                </Suspense>
                {searchState && ((searchValue && searchValue.length > 0) || (searchParams && searchParams.length > 0) ) ? (
                    <>
                        <div className='grid grid-cols-12 my-10'>
                            <div className='col-start-2 col-span-10 xl:col-start-3 xl:col-span-8'>
                                <SearchResults searchValue={searchValue} />
                                <div className='flex justify-end'>
                                    <FixBtnOnScrollDown action='scroll-bottom-top' className='bottom-4' />
                                </div>
                            </div>
                        </div>
                    </>
                ) : (
                    <>
                        <div className='grid grid-cols-12'>
                            {children}
                            <div className='col-start-2 col-span-10 xl:col-start-3 xl:col-span-8'>
                                <div className='flex justify-end'>
                                    <FixBtnOnScrollDown action='scroll-bottom-top' className='bottom-4' />
                                </div>
                            </div>
                        </div>
                    </>
                )}
    
                <Suspense fallback={renderLoader()}>
                    <GlobalDrawer />
                </Suspense>
                <Suspense fallback={renderLoader()}>
                    <div className='grid grid-cols-12 bg-white text-blue-light dark:bg-blue-dark dark:text-white'>
                        <div className='col-start-2 col-span-10 xl:col-start-3 xl:col-span-8 my-10'>
                            <Footer />
                        </div>
                    </div>
                </Suspense>
            </main>
        </div>
    );
    
}