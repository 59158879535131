/* eslint-disable @typescript-eslint/no-unused-vars */
'use client';
import { Text } from 'components/ui/text';
import { CardWithBasicInfo } from 'types/cards.type';
import { Button } from 'components/ui/button';
import ResponsiveImage from 'components/ui/responsiveimage';
import cn from 'utils/class-names';
import { Link } from 'components/link';

interface ProductProps {
  pck: CardWithBasicInfo;
  isShowPicture: boolean;
  btnInfo: {title: string; link: string};
  view: string;
}

export default function IndivCard({ pck, isShowPicture = false, btnInfo, view = 'hp' }: ProductProps) {
    const {
        type,
        imageName,
        description,
        name,
        platform,
        productVersion,
        productName,
        score,
        categories
    } = pck;

    return (
        <div className='bg-white dark:bg-blue-dark dark:text-white rounded-lg'>
            <div className='flex flex-col flex-1 min-h-full'>
                {isShowPicture && (
                    <>
                        <Link href={`${btnInfo.link}`}><ResponsiveImage imageName={imageName} alt={name} className='rounded-lg rounded-b-none' /></Link>
                        <div className='-mt-4 px-4 pb-4'></div>
                    </>
                )}
                <div className='pt-2 px-4'>
                    <Text className={cn({
                        'pt-4 leading-none tracking-wide font-title font-bold capitalize text-4xl mb-2': true,
                        'text-sm xl:text-xl 2xl:text-xl' : (view === 'hp'),
                        'text-lg' : view !== 'hp' 
                    })}>
                        <Link href={`${btnInfo.link}`} className="blue-link">{name}</Link>
                    </Text>
                    {
                        platform && platform.length > 0 && (
                            <span
                                className='font-text font-thin label'
                            >{platform}
                            </span>
                        )
                    }

                    {
                        categories && categories.length > 0 && (
                            <div className='flex justify-start mr-2'>
                                {
                                    categories.map((categoryName: string, index: number) => (
                                        <div
                                            key={index}
                                            className='text-sm font-thin me-2'
                                        >
                                            {categoryName}
                                        </div>
                                    ))
                                }
                            </div>
                        )
                    }

                    {
                        type && type === 'patches' && (
                            <span
                                className='font-text font-thin label'
                            >Patch for {productName} ({productVersion?.join(', ')})
                            </span>
                        )
                    }
                </div>
                <div className='px-4 space-y-2 mb-3'>
                    <Text className='pt-4 leading-5 dark:text-white line-clamp-3'>
                        {description && description.length > 0 ? description : 'No description'}
                    </Text>
                </div>
                <div className='mt-auto'>
                    <div className='border-t border-solid border-blue-light border-opacity-20 dark:border-opacity-60 py-4'>
                        <div className='text-left space-x-3 px-4'>
                            <Link
                                href={`${btnInfo.link}`}
                                className='text-primary-dark dark:text-white'
                                skipClientSideRouting={view === 'search' ? true : false}
                            >
                                <Button
                                    variant='outline'
                                    onClick={() => window.scrollTo(0, 0)}
                                    className='uppercase'
                                > {btnInfo.title} </Button>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}