import React, { useState, useEffect } from 'react';
import { useDrawer, type DrawerPlacements } from 'components/drawer-views/use-drawer';
import { Button } from './button';
import FaArrowUp from 'assets/fa-arrowup-icon';
import FaSliders from 'assets/fa-sliders-icon';

type fixBtnOnScrollDownTypes =  {
    action: string;
    className: string;
    modalView?: React.ReactNode;
    overlay?: string;
    placement?: DrawerPlacements;
}
export default function FixBtnOnScrollDown({action, className, modalView, overlay = 'noblur', placement = 'right'}:fixBtnOnScrollDownTypes ) {

    const [isVisible, setIsVisible] = useState(false);
    const { openDrawer } = useDrawer();

    useEffect(() => {
        const handleScroll = () => {
            const scrollTop = window.scrollY;
            const threshold = 500;

            setIsVisible(scrollTop > threshold);
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };

    }, []);
    let actionToBeExecuted;
    if (action === 'scroll-bottom-top') {
        actionToBeExecuted = () => {
            window.scrollTo({
                top: 0,
                behavior: 'smooth',
            });
        };
    } else if (action === 'filters-drawer') {
        actionToBeExecuted = () => {
            openDrawer({
                view: modalView,
                placement,
                overlay: overlay
            })
        };
    }

    return (
        <Button
            className={`fixed ${className} bg-blue-dark dark:text-blue-dark dark:bg-white hover:bg-blue-dark dark:hover:bg-white rounded-lg transition-opacity ${isVisible ? 'opacity-100' : 'opacity-0'}`}
            onClick={actionToBeExecuted} aria-label={action} size='sm'
        >
            {
                action === 'scroll-bottom-top' &&
                <FaArrowUp themeColor='dark:text-blue-light text-white'/>
            }
            {
                action === 'filters-drawer' &&
                <FaSliders themeColor='dark:text-blue-light text-white'/>
            }
        </Button>
    );

}
