import allProductsJson from 'data/products/all-products.json';
import { ProductWithBasicInfo } from 'types/packages.type';

import adoptopenjdkjrelinuxx64 from 'data/packages/adoptopenjdk-jre-linux-x64.json';
import adoptopenjdkjresolarissparcv9 from 'data/packages/adoptopenjdk-jre-solaris-sparcv9.json';
import adoptopenjdkjrewindowsx64 from 'data/packages/adoptopenjdk-jre-windows-x64.json';
import bmcsoftwareinstallationutility from 'data/packages/bmc-software-installation-utility.json';
import caarcservebackupkm from 'data/packages/ca-arcserve-backup-km.json';
import cephcapacityviews from 'data/packages/ceph-capacity-views.json';
import cmaforlegacykm from 'data/packages/cma-for-legacy-km.json';
import dellemcelasticcloudstorageetl from 'data/packages/dell-emc-elastic-cloud-storage-etl.json';
import dellemcunitycimserveretl from 'data/packages/dell-emc-unity-cim-server-etl.json';
import dellemcunitykm from 'data/packages/dell-emc-unity-km.json';
import emcdiskarrayskm from 'data/packages/emc-disk-arrays-km.json';
import emcnetworkerkm from 'data/packages/emc-networker-km.json';
import emcsmisagentetl from 'data/packages/emc-smis-agent-etl.json';
import emcxtremiomanagementserveretl from 'data/packages/emc-xtremio-management-server-etl.json';
import emcxtremiokm from 'data/packages/emc-xtremio-km.json';
import hardwareconnectors from 'data/packages/hardware-connectors.json';
import hardwaresentry from 'data/packages/hardware-sentry.json';
import hardwaresentrydashboardhelix from 'data/packages/hardware-sentry-dashboard-helix.json';
import hardwaresentrykm from 'data/packages/hardware-sentry-km.json';
import hitachidevicemanageretl from 'data/packages/hitachi-device-manager-etl.json';
import hitachidiskarrayskm from 'data/packages/hitachi-disk-arrays-km.json';
import hp3paretl from 'data/packages/hp-3par-etl.json';
import hp3parkm from 'data/packages/hp-3par-km.json';
import hpdataprotectorkm from 'data/packages/hp-data-protector-km.json';
import hpevakm from 'data/packages/hp-eva-km.json';
import ibmds3000ds4000ds5000serieskm from 'data/packages/ibm-ds3000-ds4000-ds5000-series-km.json';
import ibmds6000ds8000serieskm from 'data/packages/ibm-ds6000-ds8000-series-km.json';
import ibmspectrumprotectkm from 'data/packages/ibm-spectrum-protect-km.json';
import ibmsvcstorwizecimagentetlfortruesight from 'data/packages/ibm-svc-storwize-cim-agent-etl.json';
import ibmsvcstorwizekm from 'data/packages/ibm-svc-storwize-km.json';
import ibmxivetl from 'data/packages/ibm-xiv-etl.json';
import ibmxivkm from 'data/packages/ibm-xiv-km.json';
import monitoringstudiokm from 'data/packages/monitoring-studio-km.json';
import monitoringstudiox from 'data/packages/monitoring-studio-x.json';
import netappcapacityviews from 'data/packages/netapp-capacity-views.json';
import netappdataontapetl from 'data/packages/netapp-data-ontap-etl.json';
import netappfilerskm from 'data/packages/netapp-filers-km.json';
import purestoragekm from 'data/packages/pure-storage-km.json';
import purestoragerestapietl from 'data/packages/pure-storage-rest-api-etl.json';
import storageallinoneetl from 'data/packages/storage-all-in-one-etl.json';
import storageanalyzerkm from 'data/packages/storage-analyzer-km.json';
import storagecapacityviewsandreports from 'data/packages/storage-capacity-views-and-reports.json';
import troubleshootingtoolsfordellcompellent from 'data/packages/troubleshooting-tools-dell-compellent.json';
import troubleshootingtoolsfordellemcunity from 'data/packages/troubleshooting-tools-for-dell-emc-unity.json';
import troubleshootingtoolsforemcdiskarrays from 'data/packages/troubleshooting-tools-for-emc-disk-arrays.json';
import troubleshootingtoolsforemcxtremio from 'data/packages/troubleshooting-tools-for-emc-xtremio.json';
import troubleshootingtoolsforhitachidevicemanager from 'data/packages/troubleshooting-tools-for-hitachi-device-manager.json';
import troubleshootingtoolsforhp3par from 'data/packages/troubleshooting-tools-for-hp3par.json';
import troubleshootingtoolsforibmsvcstorwize from 'data/packages/troubleshooting-tools-for-ibm-svc-storwize.json';
import troubleshootingtoolsforibmxiv from 'data/packages/troubleshooting-tools-for-ibm-xiv.json';
import troubleshootingtoolsfornetapp from 'data/packages/troubleshooting-tools-for-netapp.json';
import troubleshootingtoolsforpurestorage from 'data/packages/troubleshooting-tools-for-pure-storage.json';
import troubleshootingtoolsvmwareconnectiontool from 'data/packages/troubleshooting-tools-vmware-connection-tool.json';
import troubleshootingtoolswbem from 'data/packages/troubleshooting-tools-wbem.json';
import veritasclusterserverkm from 'data/packages/veritas-cluster-server-km.json';
import veritasfilesystemkm from 'data/packages/veritas-file-system-km.json';
import veritasnetbackupkm from 'data/packages/veritas-netbackup-km.json';
import veritasvolumemanagerkm from 'data/packages/veritas-volume-manager-km.json';

/**
 * Array of packages to be updated manually
 * depending on releases. Object are typed to avoid
 * errors
 */
const allProducts: Array<ProductWithBasicInfo> = allProductsJson;

/**
 * Array of packages to that will be placed in the Homepage
 * Element should match a package id as defined in "allProducts"
 */
export const allProductsInHomePage: Array<string> = ['hardware-sentry-km', 'storage-analyzer-km', 'monitoring-studio-x', 'storage-all-in-one-etl'];

/**
 * Is called when the application is initialized so that all components
 * already have a 'allProducts' whitout re-computing the list. Ordering
 * by date is usefull for presenting the 'Latest Updates' on HomePage  
 */
export function allProductsWithBasicInfo(): Array<ProductWithBasicInfo> {
    return allProducts.sort((a, b) => {
        const dateA = a.latestReleaseDate === 'N.A.' ? new Date(0) : new Date(a.latestReleaseDate);
        const dateB = b.latestReleaseDate === 'N.A.' ? new Date(0) : new Date(b.latestReleaseDate);
        return dateB.getTime() - dateA.getTime();
    });
}

/**
 * Returns Product with Basic Info by productId  
 */
export function productWithBasicInfoById(id: any): any {
    return allProducts.find((product: any) => product.productId === id);
}

const jsonMappings: Record<string, any> = {
    'adoptopenjdk-jre-linux-x64': adoptopenjdkjrelinuxx64,
    'adoptopenjdk-jre-solaris-sparcv9': adoptopenjdkjresolarissparcv9,
    'adoptopenjdk-jre-windows-x64': adoptopenjdkjrewindowsx64,
    'bmc-software-installation-utility': bmcsoftwareinstallationutility,
    'ca-arcserve-backup-km': caarcservebackupkm,
    'ceph-capacity-views': cephcapacityviews,
    'cma-for-legacy-km': cmaforlegacykm,
    'dell-emc-elastic-cloud-storage-etl': dellemcelasticcloudstorageetl,
    'dell-emc-unity-cim-server-etl': dellemcunitycimserveretl,
    'dell-emc-unity-km': dellemcunitykm,
    'emc-disk-arrays-km': emcdiskarrayskm,
    'emc-networker-km': emcnetworkerkm,
    'emc-smis-agent-etl': emcsmisagentetl,
    'emc-xtremio-management-server-etl': emcxtremiomanagementserveretl,
    'emc-xtremio-km': emcxtremiokm,
    'hardware-connectors': hardwareconnectors,
    'hardware-sentry': hardwaresentry,
    'hardware-sentry-dashboard-helix': hardwaresentrydashboardhelix,
    'hardware-sentry-km': hardwaresentrykm,
    'hitachi-device-manager-etl': hitachidevicemanageretl,
    'hitachi-disk-arrays-km': hitachidiskarrayskm,
    'hp-3par-etl': hp3paretl,
    'hp-3par-km': hp3parkm,
    'hp-data-protector-km': hpdataprotectorkm,
    'hp-eva-km': hpevakm,
    'ibm-ds3000-ds4000-ds5000-series-km': ibmds3000ds4000ds5000serieskm,
    'ibm-ds6000-ds8000-series-km': ibmds6000ds8000serieskm,
    'ibm-spectrum-protect-km': ibmspectrumprotectkm,
    'ibm-svc-storwize-cim-agent-etl': ibmsvcstorwizecimagentetlfortruesight,
    'ibm-svc-storwize-km': ibmsvcstorwizekm,
    'ibm-xiv-etl': ibmxivetl,
    'ibm-xiv-km': ibmxivkm,
    'monitoring-studio-km': monitoringstudiokm,
    'monitoring-studio-x': monitoringstudiox,
    'netapp-capacity-views': netappcapacityviews,
    'netapp-data-ontap-etl': netappdataontapetl,
    'netapp-filers-km': netappfilerskm,
    'pure-storage-km': purestoragekm,
    'pure-storage-rest-api-etl': purestoragerestapietl,
    'storage-all-in-one-etl': storageallinoneetl,
    'storage-analyzer-km': storageanalyzerkm,
    'storage-capacity-views-and-reports': storagecapacityviewsandreports,
    'troubleshooting-tools-dell-compellent': troubleshootingtoolsfordellcompellent,
    'troubleshooting-tools-for-dell-emc-unity': troubleshootingtoolsfordellemcunity,
    'troubleshooting-tools-for-emc-disk-arrays': troubleshootingtoolsforemcdiskarrays,
    'troubleshooting-tools-for-emc-xtremio': troubleshootingtoolsforemcxtremio,
    'troubleshooting-tools-for-hitachi-device-manager': troubleshootingtoolsforhitachidevicemanager,
    'troubleshooting-tools-for-hp3par': troubleshootingtoolsforhp3par,
    'troubleshooting-tools-for-ibm-svc-storwize': troubleshootingtoolsforibmsvcstorwize,
    'troubleshooting-tools-for-ibm-xiv': troubleshootingtoolsforibmxiv,
    'troubleshooting-tools-for-netapp': troubleshootingtoolsfornetapp,
    'troubleshooting-tools-for-pure-storage': troubleshootingtoolsforpurestorage,
    'troubleshooting-tools-vmware-connection-tool': troubleshootingtoolsvmwareconnectiontool,
    'troubleshooting-tools-wbem': troubleshootingtoolswbem,
    'veritas-cluster-server-km': veritasclusterserverkm,
    'veritas-file-system-km': veritasfilesystemkm,
    'veritas-netbackup-km': veritasnetbackupkm,
    'veritas-volume-manager-km': veritasvolumemanagerkm
};

export function pckWithMavenInfo(packageId: string) {

    const pckWithMavenCoordinates = jsonMappings[packageId];
    pckWithMavenCoordinates.packages.sort((a: any, b: any) => {
        const versionA = a.coordinates.version;
        const versionB = b.coordinates.version;
  
        // Parse versions and compare
        const parsedVersionA = parseVersion(versionA);
        const parsedVersionB = parseVersion(versionB);
  
        if (parsedVersionA > parsedVersionB) {
            return -1;
        } else if (parsedVersionA < parsedVersionB) {
            return 1;
        } else {
            return 0;
        }
    });

    return pckWithMavenCoordinates;

}

function parseVersion(version: string) {
    // Parse version string and return a comparable value
    return version.split('.').map(Number).reduce((acc, val) => acc * 1000 + val, 0);
}

