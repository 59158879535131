const url = '/api/download'

interface ApiResponse {
    signedCloudfrontUrl: string;
}

const fetchProtectedArtefact = async (item: any, type: string, user: any, accessTokenFromAuth0: any): Promise<ApiResponse> => {
    try {

        let itemKey;
        if (type === 'patch') {
            itemKey = `patches/${item}`;
        } else {
            itemKey = item;
        }

        const queryParams = new URLSearchParams({
            email: user.email,
            name: user.name || '',
            file_path: itemKey
        });

        const fullUrl = `${url}?${queryParams.toString()}`;

        const response = await fetch(fullUrl, {
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Headers': '*',
                'Access-Control-Allow-Methods': 'OPTIONS,POST,GET',
                'Authorization': `Bearer ${accessTokenFromAuth0}`
            },
        });
  
        if (response.ok) {
            const data = await response.json();
            return data as ApiResponse;
        } else {
            throw new Error(`API request failed with status: ${response.status}`);
        }
    } catch (error) {
        console.error('Error fetching data:', error);
        throw error;
    }
};

const fetchNonProtectedArtefact = async (item: any): Promise<ApiResponse> => {
    try {

        const queryParams = new URLSearchParams({
            file_path: item
        });

        const fullUrl = `${url}?${queryParams.toString()}`;
        console.log('itemKey', item);

        const response = await fetch(fullUrl, {
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Headers': '*',
                'Access-Control-Allow-Methods': 'OPTIONS,POST,GET'
            },
        });
  
        if (response.ok) {
            const data = await response.json();
            return data as ApiResponse;
        } else {
            throw new Error(`API request failed with status: ${response.status}`);
        }
    } catch (error) {
        console.error('Error fetching data:', error);
        throw error;
    }
};
  
export { fetchProtectedArtefact, fetchNonProtectedArtefact };
