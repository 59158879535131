import React, { useEffect, useState} from 'react';
import { useLocation, useParams, useSearchParams } from 'react-router-dom';
import { allProductsInHomePage, pckWithMavenInfo, productWithBasicInfoById } from 'data/all-products';
import { useAuth0 } from '@auth0/auth0-react';
import config from 'config/site.config';
import { Link } from 'components/link';
import { Text } from 'components/ui/text';
import NoItems from 'pages/404';
import Related from 'components/related';
import DownloadBox from 'components/download-box';
import { Alert } from 'components/ui/alert';
import ResponsiveImage from 'components/ui/responsiveimage';
import FaArrowUpRightFromSquareIcon from 'assets/fa-arrow-up-right-from-square';

interface PackageData {
    packageData: any;
    packageId: string;
}

const CORPORATE_URL = config('REACT_APP_CORPORATE_URL');

export const PackagesId = () => {

    const [packageData, setPackageData] = useState(null);
    const [isPck, setIsPck] = useState(true);
    const { packageId } = useParams();

    React.useEffect(() => {
        const abortController = new AbortController();
        void async function fetchData() {
            try {
                const packageModule = await import(`data/releases/${packageId}-releases.json`);
                setPackageData(packageModule.default);
            } catch (error) {
                setIsPck(false);
                console.log('error', error);
            }
        }();
        return () => {
            abortController.abort();
        };
    }, [packageId]);

    return (
        <>
            {isPck ? (
                <>
                    {
                        packageData && (
                            <ShowPackagePage packageData={packageData} packageId={packageId || ''} />
                        )

                    }
                </>
            ) :
                < NoItems message={`Package not found: ${packageId}`} />
            }
        </>
    )

}

const LicenseLink = (packagesInfo: any) => {

    const licenseInfo = {
        url: `${CORPORATE_URL}/legal/commercial-license.html`,
        required: true,
        text: 'By downloading you accept the Sentry for BMC Software License'
    }

    const licenseName = packagesInfo.packagesInfo.license.toLowerCase();

    if (licenseName === '' || licenseName === 'free') {
        licenseInfo.url = `${CORPORATE_URL}/legal/free-license.html`;
        licenseInfo.required = true;
        licenseInfo.text = 'By downloading you accept the Sentry Free Software License';
    } else if (licenseName === 'no') {
        licenseInfo.url = '';
        licenseInfo.required = false;
    }

    if (licenseInfo.required === true) {
        return (
            <Link
                href={licenseInfo.url}
                className='mr-3 blue-link'
            >
                {licenseInfo.text}
            </Link>
        );
    }

    return null;
}

const ProductLink = (data: any) => {
    return (
        <>
            {
                (data.packagesInfo && data.packagesInfo.linkCorporateSite && data.packagesInfo.linkCorporateSite.length > 0) ? 

                    <Link
                        href={`${CORPORATE_URL}${data.packagesInfo.linkCorporateSite}`}
                        className='blue-link cursor-pointer'
                    >
                        More Info <FaArrowUpRightFromSquareIcon />
                    </Link>

                    : null
            }
        </>
    )
}

const ShowPackagePage: React.FC<PackageData> = ({packageData, packageId}) => {

    const packagesInfo = productWithBasicInfoById(packageId);
    const mavenInfo = pckWithMavenInfo(packagesInfo.productId);
    const { user, isAuthenticated, loginWithRedirect } = useAuth0();
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [searchParams, setSearchParams] = useSearchParams();


    const location = useLocation();
    const versionInQueryParameter = new URLSearchParams(location.search).get('version');

    let isSupersededBy = false;
    let packageSuperseder = '';
    if (packagesInfo && packagesInfo.supersededByProductId && packagesInfo.supersededByProductId.length > 0) {
        isSupersededBy = true;
        packageSuperseder = productWithBasicInfoById(packagesInfo.supersededByProductId).name;
    }

    useEffect(() => {
        
        if (versionInQueryParameter) {
            const findVersion = mavenInfo.packages.find((element: any) => element.coordinates.version == versionInQueryParameter);
            if (!findVersion) {
                setSearchParams({ version: 'latest' });
            }
        }
    }, []);

    return (
        <div className='@container col-start-2 col-span-10 xl:col-start-3 xl:col-span-8 my-10'>
            <div className='grid grid-cols-6 gap-4'>
                <div className='col-span-6 lg:col-span-6'>
                    {
                        allProductsInHomePage.includes(packagesInfo.productId) && (
                            <ResponsiveImage imageName={packagesInfo.productId} alt={packagesInfo.name} className='rounded-lg hidden' />
                        )
                    }
                    <h1 className='tracking-wide font-bold text-3xl font-title capitalize'>{packagesInfo.name}</h1>
                    <Text className='font-semibold text-lg'>
                        {packagesInfo.description}
                    </Text>
                    <ProductLink packagesInfo={packagesInfo} className='my-2'/>
                    {
                        isSupersededBy && (
                            <Alert color='warning' bar={true} variant='flat' className='mb-3 mt-3'>
                                <Text className='font-semibold'>This product is superseded by <Link href={`/packages/${packagesInfo.supersededByProductId}`} className='underline'>{packageSuperseder}</Link> </Text>
                            </Alert>
                        )
                    }
                    <div className='bg-white dark:bg-blue-dark dark:text-white rounded-lg mt-2'>
                        <DownloadBox packageData={packageData} packagesInfo={packagesInfo} mavenInfo={mavenInfo}/>
                    </div>
                    <div className='py-3 md:flex md:justify-start md:py-5'>
                        {(!user || !isAuthenticated) && (
                            <>
                                <LicenseLink packagesInfo={packagesInfo} className={'blue-link'} />
                                <div
                                    onClick={() => loginWithRedirect({ appState: { returnTo: window.location.pathname } })}
                                    className='blue-link cursor-pointer'
                                >
                                    Log In to download
                                </div>
                            </>
                        )}
                        {(user && user.email_verified === false ) && (
                            <div className='text-red-dark'>
                                Verify your Email to download products
                            </div>
                        )}
                    </div>
                    <div className='py-3'>
                        <Related packageData={packageData} packagesInfo={packagesInfo} mavenInfo={mavenInfo}/>
                    </div>
                    
                </div>
            </div>
        </div>
    );

}
