/* eslint-disable @typescript-eslint/no-unused-vars */

export default function NoItems({message}: {message: string}) {
    return (
        <>
            <section className='overflow-hidden relative w-screen'>
                <div className='image-background'>
                    <div className='w-6/12'>
                        <div className='pl-20 pt-20 xl:pl-32 xl:pt-32 text-overlay top-0'>
                            <h1 className='text-3xl font-bold md:text-4xl z-10 mt-3 dark:text-blue-light dark:text-opacity-80'>Oops! 404 Error...</h1>
                            <p className='text-left mt-2 text-base dark:text-blue-light dark:text-opacity-80 text-md md:text-lg lg:text-2xl'>
                                Sorry, the page you are looking for is missing, has been removed, had its name changed, or is temporarily unavailable.
                            </p>
                        </div>
                    </div>
                </div>
            </section>

        </>
    );
}
