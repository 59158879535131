import React, { lazy, Suspense, useEffect, useState } from 'react';
import { pckFilters } from 'data/filter/filter-packages';
import { ProductWithBasicInfo } from 'types/packages.type';
import { useLocation  } from 'react-router-dom';
import { FilterList } from 'layouts/filters-query-params/filters-list';
const ListCards = lazy(() => import('components/cards/list-cards'));
import { FiltersButton } from 'layouts/filters-query-params/filters-button';
import FixBtnOnScrollDown from 'components/ui/fix-button-on-scroll-down';
import { allProductsWithBasicInfo } from 'data/all-products';
import { CardsList } from 'types/cards.type';

const renderLoader = () => <p></p>;

export default function AllPackagesPage() {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const [queryParamsState, setQueryParamsState] = useState(Object.fromEntries(queryParams.entries()));

    useEffect(() => {
        setQueryParamsState(Object.fromEntries(queryParams.entries()));
    }, [location.search]);

    const findAllProducts = () => {
        const productsId: string[] = [];
    
        if (Object.entries(queryParamsState).length > 0) {

            allProductsWithBasicInfo().forEach((product: ProductWithBasicInfo) => {
                const matchesAllParams = Object.entries(queryParamsState).every(([id, value]) => {

                    return product.filters[id] === value;
                });
    
                if (matchesAllParams) {
                    productsId.push(product.productId);
                }
            });
        } else {
            allProductsWithBasicInfo().forEach((product: ProductWithBasicInfo) => {
                productsId.push(product.productId);
            })
        }
        
        return productsId;
    };
    
    const pckList: CardsList = {
        'products': findAllProducts()
    };

    return (
        <>
            <div className='@container col-start-2 col-span-10 xl:col-start-3 xl:col-span-8 my-10'>
                <div className=''>    
                    <div className='lg:hidden text-right pb-2'>
                        <FiltersButton
                            className='text-white bg-blue-dark uppercase dark:text-blue-dark dark:bg-white hover:bg-blue-dark dark:hover:bg-white'
                            placement='right'
                            overlay='noblur'
                            modalView={<FilterList filterElements={pckFilters} type='sm' />}
                            isMobileOnly = {false}
                        />
                        {/* <FiltersSortButton sortPackagesList={sortPackagesList} /> */}
                    </div>
                </div>
                <div className='grid grid-cols-10 gap-4'>
                    <div className='col-span-2 hidden lg:block'>
                        <FilterList filterElements={pckFilters} />
                    </div>
                    <div className='col-span-10 lg:col-span-8'>
                        <Suspense fallback={renderLoader()}>
                            <ListCards
                                className='grid grid-cols-1 md:grid-cols-2 md:gap-4 lg:grid-cols-3 lg:gap-4'
                                sortFunction={'alphabetical'}
                                pckList={pckList}
                                view={'pck'}
                            />
                        </Suspense>
                    </div>
                </div>
            </div>
            <div className='col-start-2 col-span-10 xl:col-start-3 xl:col-span-8'>
                <div className='flex justify-end'>
                    <FixBtnOnScrollDown action='filters-drawer' className='bottom-20' modalView={<FilterList filterElements={pckFilters} type='sm'/>}/>
                </div>
            </div>
        </>
    );
}