const getEnvVariable = (key: string): string | undefined => {
    return process.env[key];
};

const siteConfigKeys = [
    'REACT_APP_AUTH0_AUDIENCE',
    'REACT_APP_AUTH0_CLIENT_ID',
    'REACT_APP_AUTH0_DOMAIN',
    'REACT_APP_AUTH0_SCOPE',
    'REACT_APP_CORPORATE_URL',
    'REACT_APP_DOCUMENTATION_URL',
    'REACT_APP_SUPPORT_URL'
];

const config: { [key: string]: string | undefined } = {};

siteConfigKeys.forEach((key: string) => {
    config[key] = getEnvVariable(key);
});

function fnConfig(key: string) {
    return config[key] || '';
}
export default fnConfig;
