import { Button } from 'components/ui/button';
import { useDrawer } from 'components/drawer-views/use-drawer';

export default function RemoveSidebarFilters({className}: {className: string}) {

    const { closeDrawer } = useDrawer();

    return (
        <div >
            <Button
                size='md'
                variant='solid'
                className={`${className} w-3/4 text-white bg-blue-dark uppercase dark:text-blue-dark dark:bg-white hover:bg-blue-dark dark:hover:bg-white`}
                onClick={() => closeDrawer()}
            >
                Show results
            </Button>
        </div>
    )
}