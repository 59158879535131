import React, { useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';
import 'globals.css';
import reportWebVitals from 'reportWebVitals';
import { ThemeProvider } from 'contexts/theme-context';
import { allProductsWithBasicInfo } from 'data/all-products';
import { Auth0ProviderWithNavigate } from 'components/auth0';
import { AppRouter } from 'routing';
import { ApplicationLayout } from 'layouts/application-layout';

const initializeApplication = () => {
    // Initialization logic here
    return allProductsWithBasicInfo;
};

const App: React.FC = () => {
    // Call the initialization function once when the component mounts
    useEffect(() => {
        initializeApplication();
    }, []); // Empty dependency array means this effect runs once on mount

    return (
        <React.StrictMode>
            <ThemeProvider>
                <BrowserRouter>
                    <Auth0ProviderWithNavigate>
                        <ApplicationLayout>
                            <AppRouter />
                        </ApplicationLayout>
                    </Auth0ProviderWithNavigate>
                </BrowserRouter>
            </ThemeProvider>
        </React.StrictMode>
    );
};

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
// reportWebVitals(console.log)

export default App; // Export the App component
