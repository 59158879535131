import { Link, Outlet } from 'react-router-dom';
import ListCards from 'components/cards/list-cards';
import { Button } from 'rizzui';
import { allProductsInHomePage, allProductsWithBasicInfo } from 'data/all-products';
import Table from 'components/table';

export const Home: React.FC = () => {
    
    const pckList = {
        'products': allProductsInHomePage
    };

    return (
        <>
            <div className='col-start-2 col-span-10 xl:col-start-3 xl:col-span-8 my-10'>
                <h1 className='sr-only'>Download Website</h1>
                <div className='flex flex-col xl:flex-row gap-5'>
                    <div className='w-full sm:w-12/12 md:w-12/12 lg:w-12/12 xl:w-6/12 2xl:w-8/12'>
                        <h2 className='text-center mt-5 mb-10 uppercase tracking-wide font-bold text-3xl font-title heading-block'>Popular Products</h2>
                        <div className='@container'>
                            <div className='grid'>
                                <ListCards
                                    className='grid grid-cols-1 2xl:grid-cols-2 xl:grid-cols-1 lg:grid-cols-2 md:grid-cols-2 md:gap-4'
                                    sortFunction={'none'}
                                    view={'hp'}
                                    pckList={pckList}
                                    isShowPicture={true}
                                />
                            </div>
                            <Outlet />
                        </div>
                    </div>
                    <div className='w-full sm:w-12/12 md:w-12/12 lg:w-12/12 xl:w-6/12 2xl:w-4/12'>
                        <h2 className='text-center mt-5 mb-10 uppercase tracking-wide font-bold text-3xl font-title heading-block'>Latest updates</h2>
                        <div className='@container'>
                            <Table pckList={allProductsWithBasicInfo().slice(0, 13)}/>
                            <Link to={'./packages'}>
                                <Button
                                    variant='solid'
                                    onClick={() => window.scrollTo(0, 0)}
                                    className='mt-5 rizzui-button'
                                >
                                    All packages
                                </Button>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
