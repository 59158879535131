import { useState } from 'react';
import FaFileZipperIcon from 'assets/fa-file-zipper';
import { User, useAuth0 } from '@auth0/auth0-react';
import { fetchNonProtectedArtefact, fetchProtectedArtefact } from 'services/api-client';
import { Loader } from 'components/ui/loader';
import { Link } from 'components/link';

export interface DownloadProps {
    className?: string;
    item: string;
    link: string;
    protectedItem: boolean;
    type: string;
}

export function Download({className, item, link, protectedItem, type}: DownloadProps) {

    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState<Error | null | string>(null);
    const [errorMessage, setErrorMessage] = useState<string>('');
    const { user, isAuthenticated, getAccessTokenSilently, loginWithRedirect } = useAuth0();

    const fetchPrivateDownload = async (item: any, type: string, user: User, accessToken: string) => {
        try {
            const response = await fetchProtectedArtefact(item, type, user, accessToken);
            window.open(response.signedCloudfrontUrl, '_blank');
        } catch (error: any) {
            setError(error);
        } finally {
            setIsLoading(false);
        }
    }

    const fetchPublicDownload = async (item: any) => {
        try {
            const response = await fetchNonProtectedArtefact(item);
            window.open(response.signedCloudfrontUrl, '_blank');
        } catch (error: any) {
            setError(error);
            setErrorMessage('Download failed');
        } finally {
            setIsLoading(false);
        }
    }

    const onClickOnDownloadBtn = async (item: any, type: string) => {
        if (!protectedItem) {
            await fetchPublicDownload(item);
        } else {
            if (!user || !isAuthenticated) {
                loginWithRedirect({ appState: { returnTo: window.location.pathname } });
            } else {
                if (user.email_verified == false) {
                    setError('Verify your Email and refresh the page');
                    setErrorMessage('Verify your Email and refresh the page');
                } else {
                    setIsLoading(true);
                    setError(null);
                    const accessToken = await getAccessTokenSilently();
                    await fetchPrivateDownload(item, type, user, accessToken);
                }

            }
        }
    }


    return (
        <div className={`${className} flex`}>
            <FaFileZipperIcon themeColor={'yellow-icon'} />
            {protectedItem === false && (
                <Link href={`/public/${link}`} className='cursor-pointer font-bold blue-link' skipClientSideRouting={true}>
                    {item}
                </Link>
            )}
            {protectedItem === true && (
                <>
                    <>
                        {
                            isLoading ? (
                                <div className='font-bold'>
                                    {item}
                                </div>
                            ) : (
                                <div onClick={() => onClickOnDownloadBtn(link, type)} className='cursor-pointer font-bold blue-link'>
                                    {item}
                                </div>
                            )
                        }
                    </>
                    <p className='min-h-5'>
                        {
                            isLoading && (
                                <Loader size='sm'/>
                            )
                        }
                    </p>
                    {error && (
                        <p className='text-red-dark'>{errorMessage}</p>
                    )}
                </>
            )}
        </div>
    )
}
