import { Suspense, lazy, useEffect, useMemo, useState } from 'react';
const ListCards = lazy(() => import('components/cards/list-cards'));
import Fuse from 'fuse.js';
import PatchesIndex from 'data/search/index-patches.json';
import ProductsIndex from 'data/search/index-products.json';
import debounce from 'lodash/debounce';

const renderLoader = () => <p>Loading...</p>;

export function SearchResults({searchValue}: {searchValue: string}) {
    const [searchResults, setSearchResults] = useState<any>({products: [], patches: []});
    
    const fuseOptions = {
        keys: [
            { name: 'name', weight: 1 },
            { name: 'content', weight: 1 },
        ],
        includeScore: true,
        isCaseSensitive: false,
        threshold: 0.55,
        ignoreLocation: true,
        useExtendedSearch: true,
        ignoreFieldNorm: true
    };
    
    // Initialize Fuse with the data and options (useMemo for memoization)
    const indexToBeMerged = Array.from(new Set([...PatchesIndex, ...ProductsIndex]));
    const indexFuse = useMemo(() => new Fuse(indexToBeMerged, fuseOptions), []);
    
    useEffect(() => {
        const debouncedSearch = debounce(async (currentSearchQuery) => {
            if (!currentSearchQuery) {
                setSearchResults({});
                return;
            }
            const searchResults = await indexFuse.search(currentSearchQuery);

            const getAllResults = () => {
                let results = [];
                results = searchResults.filter((result: any) => result.score < 0.1).map(result => {
                    return {
                        id: result.item.id,
                        score: result.score,
                        type: result.item.type
                    }
                });
                const msx = results.filter(el => el.id === 'monitoring-studio-x');
                const mskm = results.filter(el => el.id === 'monitoring-studio-km');
                if (msx && mskm) {
                    results.sort((id1, id2): any => {
                        if (id1.id === 'monitoring-studio-x') return -1;
                        if (id2.id === 'monitoring-studio-x') return 1;
                    })
                }
                return results;
            }
    
            setSearchResults({
                searchList: getAllResults()
            });

            
        }, 50);
    
        debouncedSearch(searchValue);
    
        // Cleanup function to clear debounce timeout on unmount
        return () => debouncedSearch.cancel();
    }, [searchValue, indexFuse]);
    
    const getTotalResultsCount = () => {
        const totalElements = Object.values(searchResults).reduce((acc: any, currentArray: any) => {
            return acc + currentArray.length;
        }, 0);
        return totalElements;
    };
    
  
    return (
        <div>
            <div className='pb-4'>
                <h1 className='flex items-center'>
                    Search Results <div className='font-bold italic pl-3'>{searchValue}</div>
                </h1>
                <p>
                    {`${getTotalResultsCount()} results found`}
                </p>
            </div>
            <Suspense fallback={renderLoader()}>
                <ListCards
                    className='grid grid-cols-1 md:grid-cols-2 md:gap-4 lg:grid-cols-3 lg:gap-4'
                    sortFunction={'none'}
                    pckList={searchResults}
                    view={'search'}
                />
            </Suspense>
        </div>
    );
}