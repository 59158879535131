import { useNavigate } from 'react-router-dom';
import { Auth0Provider } from '@auth0/auth0-react';
import config from 'config/site.config';

const audienceAuth0 = config('REACT_APP_AUTH0_AUDIENCE');
const domainAuth0 = config('REACT_APP_AUTH0_DOMAIN');
const clientIdAuth0 = config('REACT_APP_AUTH0_CLIENT_ID');
const scopeAuth0 = config('REACT_APP_AUTH0_SCOPE');

export const Auth0ProviderWithNavigate = ({ children }: {children: any}) => {
    const navigate = useNavigate();
  
    const onRedirectCallback = (appState: any) => {
        navigate(appState?.returnTo || window.location.pathname);
    };
  
    return (
        <Auth0Provider
            domain={domainAuth0}
            clientId={clientIdAuth0}
            authorizationParams={{
                redirect_uri: window.location.origin,
                audience: audienceAuth0,
                scope: scopeAuth0
            }}
            
            onRedirectCallback={onRedirectCallback}
        >
            {children}
        </Auth0Provider>
    );
};