import React from 'react';

interface FaChevronDownIconProps {
  themeColor: string;
  className: string;
}

const FaChevronDownIcon: React.FC<FaChevronDownIconProps> = ({ themeColor, className }: FaChevronDownIconProps) => (
    <svg xmlns='http://www.w3.org/2000/svg' height='10' width='10' viewBox='0 0 512 512' className={className}>
        <path className={themeColor} fill='currentColor' d='M233.4 406.6c12.5 12.5 32.8 12.5 45.3 0l192-192c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L256 338.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l192 192z' />
    </svg>

);

export default FaChevronDownIcon;
