/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { lazy, Suspense } from 'react';
const renderLoader = () => <p></p>;

const ResponsiveImage = ({ imageName, alt, className }: { imageName?: string; alt: string; className: string }) => {
    const imageSizes = [
        { width: 320, media: '(max-width: 479px)' },
        { width: 480, media: '(min-width: 480px) and (max-width: 839px)' },
        { width: 560, media: '(min-width: 560px) and (max-width: 839px)' },
        { width: 840, media: '(min-width: 840px) and (max-width: 959px)' },
        { width: 960, media: '(min-width: 960px) and (max-width: 1439px)' },
        { width: 800, media: '(min-width: 1440px)' },
    ];

    const srcset = imageSizes
        .map(({ width }) => `/images/${imageName}.webp ${width}w`)
        .join(', ');

    

    return (
        <Suspense fallback={renderLoader()}>
            <img
                src={`/images/${imageName}.webp`}
                srcSet={srcset}
                sizes='(max-width: 320px) 280px,
                (max-width: 480px) 440px,
                800px'
                alt={alt}
                className={className}
                // eslint-disable-next-line react/no-unknown-property
                fetchpriority='high'
                loading='lazy'
            />
        </Suspense>
    );
};

export default ResponsiveImage;