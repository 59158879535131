import { useEffect, useCallback , Dispatch, SetStateAction } from 'react';
import { useTheme } from 'contexts/theme-context';

type Theme = 'dark' | 'light';

export default function useDarkSide(): [Theme, Dispatch<SetStateAction<Theme>>] {
    const { theme, setTheme } = useTheme();
    const toggleTheme = useCallback(() => {
        setTheme((prevTheme) => (prevTheme === 'dark' ? 'light' : 'dark'));
    }, [setTheme]);
    
    useEffect(() => {
        const root = window.document.documentElement;
        root.classList.remove('dark', 'light');
        root.classList.add(theme);
    
    }, [theme]);
    
    return [theme, toggleTheme];
}
