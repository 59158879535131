import allPatchesJson from 'data/patches/all-patches.json';
import { PatchWithBasicInfo } from 'types/patches.type';

/**
 * Array of packages to be updated manually
 * depending on releases. Object are typed to avoid
 * errors
 */
const allPatches: Array<PatchWithBasicInfo> = allPatchesJson;

/**
 * Returns Patch with Basic Info by patchId  
 */
export function patchWithBasicInfoById(id: any): any {
    return allPatches.find((patch: any) => patch.patchId === id);
}