import FaFileCodeIcon from 'assets/fa-file-code';
import FaFileIcon from 'assets/fa-file';
import config from 'config/site.config';
import { Link } from 'components/link';

interface DocumentationLinkProps {
    docLinks: Array<{
        allLinks: Array<any>;
        linkName: string;
    }>;
    className?: string;
}

export function DocumentationLink({docLinks }: DocumentationLinkProps) {

    const CORPORATE_URL = config('REACT_APP_CORPORATE_URL');

    const createLinkToDocs = (link: string): {icon: string; linkReview: string} => {
        if (link.toLowerCase().startsWith('https://docs.bmc.com')) {
            return {
                icon: 'html',
                linkReview: link
            };
        } else if (link.toLowerCase().endsWith('.pdf') || link.toLowerCase().endsWith('.ppt')) {
            const currentURL = window.location.href;
            const baseURL = currentURL.substring(0, currentURL.indexOf('/packages'));
            return {
                icon: 'file',
                linkReview: `${baseURL}/public/${link}`
            };
        } else {
            return {
                icon: 'html',
                linkReview: CORPORATE_URL + link
            };
        }
    };

    return (
        <div className='py-3 px-3 flex justify-end md:py-5 md:px-5'>
            <div className='flex items-center justify-center'>
                {docLinks.filter(element => element.allLinks.length > 0).map((docLink, indexDocLink) => (
                    docLink.allLinks.filter(element => element != '').map((element, index) => (
                        <div key={index} className='flex items-center justify-center'>
                            {
                                createLinkToDocs(element.link).icon === 'file' ?
                                    <FaFileIcon themeColor={''} /> :
                                    <FaFileCodeIcon themeColor={''} />
                            }
                            <Link
                                href={createLinkToDocs(element.link).linkReview }
                                className={`pl-1 blue-link pr-1 ${(indexDocLink != docLinks.length - 1) ? 'mr-3' : ''} `}
                                skipClientSideRouting={true}
                            >
                                {element.name}
                            </Link>
                        </div>
                    ))
                ))}
            </div>
        </div>
    )
}
