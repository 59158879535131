import React, { useEffect, useState} from 'react';
import { useParams } from 'react-router-dom';
import Markdown from 'markdown-to-jsx';
import parseMD from 'parse-md';
import { Download } from 'components/download';
import NoItems from 'pages/404';
import { patchWithBasicInfoById } from 'data/all-patches';
import { Link } from 'components/link';


interface patchMetadataMarkdownInterface {
    fileContent: string;
    useCondition: string;
    fixedBug: string;
}

export const PatchId = () => {

    const { patchId } = useParams();
    const [patchInfo, setPatchInfo] = useState('');
    const [isPatch, setIsPatch] = useState(true);

    useEffect(() => {
        const fetchPackageData = async () => {
            try {
                const patchInfo = patchWithBasicInfoById(patchId);
                setPatchInfo(patchInfo);

                if (typeof patchInfo === 'undefined') {
                    setIsPatch(false);
                }

            } catch (error) {
                setIsPatch(false);
            }
        };

        fetchPackageData();

    }, [patchId]);


    return (
        <>
            {isPatch && patchInfo ? (
                <ShowPatchOpt patchInfo={patchInfo} />
            ) :
                <NoItems message={`Patch not found: ${patchId}`} />
            }
        </>
    );
}


const ShowPatchOpt: React.FC<any> = ({patchInfo}) => {

    const [post, setPost] = useState('');
    const [patchMetadata, setPatchMetadata] = useState<patchMetadataMarkdownInterface>({ fileContent: '', useCondition: '', fixedBug: '' });
    useEffect(() => {
        const fetchPackageData = async () => {
            const res = await import(`data/patches/${patchInfo.patchId}.md`);
            const text = await fetch(res.default).then(res => res.text());
            const { metadata, content } = parseMD(text);
            const parsedMetadata: patchMetadataMarkdownInterface = metadata as patchMetadataMarkdownInterface;
            setPost(content);
            setPatchMetadata(
                {
                    fileContent: parsedMetadata.fileContent,
                    useCondition: parsedMetadata.useCondition,
                    fixedBug: parsedMetadata.fixedBug
                }
            );
        };
        try {
            fetchPackageData();
        } catch(error) {
            fetchPackageData();
        }

        fetchPackageData();

    }, [patchInfo]);

    return (

        <div className='@container col-start-2 col-span-10 xl:col-start-3 xl:col-span-8 my-10'>
            <h1 className='tracking-wide font-bold text-3xl font-title capitalize'>
                {patchInfo.patchId}: {patchInfo.title}
            </h1>
            <div className='flex justify-between'>
                <div className='rizzui-text-p font-semibold text-lg mb-4 mt-4'>
                    Patch for <Link href={`/packages/${patchInfo?.productId}?version=${patchInfo?.versions[0]}`} label={patchInfo.name} className='blue-link'>{patchInfo?.name}</Link> - Version(s) {patchInfo?.versions?.join(', ')}
                </div>
                <div className='rizzui-text-p font-semibold text-lg mb-4 mt-4'>
                    {patchInfo?.date}
                </div>
            </div>
            <div className='grid grid-cols-6 gap-4'>
                <div className='col-span-6 lg:col-span-6'>
                    <div className='flex flex-col mx-auto bg-white dark:bg-blue-dark dark:text-white rounded-lg'>
                        <div className='py-2 px-4'>
                            <h3 className='font-bold font-title mb-2'><Markdown>{patchMetadata.fileContent || ''}</Markdown></h3>
                            {patchInfo?.downloads?.map((item: any, index: number) => {
                                return (
                                    <div key={index}>
                                        <Download
                                            className='gap-x-2 pb-2'
                                            item={item}
                                            link={`${patchInfo?.patchId}/${item}`}
                                            protectedItem= {true}
                                            type='patch'
                                        />
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div>
                <div className='col-span-6 lg:col-span-6'>
                    <h3 className='font-bold font-title mb-2'>Should you install this patch?</h3>
                    <div className='mb-6'>
                        <Markdown>{patchMetadata.useCondition || ''}</Markdown>
                    </div>
                    <h3 className='font-bold font-title mb-2'>What does it fix?</h3>
                    <div className='mb-6'>
                        <Markdown>{patchMetadata.fixedBug || ''}</Markdown>
                    </div>
                    <h3 className='font-bold font-title mb-2'>How to install the patch?</h3>
                    <Markdown options={{
                        overrides: {
                            ol: {
                                props: {
                                    className: 'list-decimal ml-6',
                                },
                            },
                            ul: {
                                props: {
                                    className: 'list-disc ml-8',
                                },
                            },
                            li: {
                                props: {
                                    className: 'mb-4',
                                },
                            },
                        },
                    }}>{post}</Markdown>
                </div>
            </div>
        </div>
    )
}
