'use client';
import IndivCard from 'components/cards/indiv-card';
import cn from 'utils/class-names';
import { allProductsWithBasicInfo } from 'data/all-products';
import Patches from 'data/patches/all-patches.json';
import { CardWithBasicInfo } from 'types/cards.type';
import { pckFilters } from 'data/filter/filter-packages';

interface ListCardsProps {
    className?: string;
    pckList: {products?: Array<string>, patches?: Array<string>, searchList?: Array<{id: string; score: string, type: 'product' | 'patch'}>};
    sortFunction?: 'alphabetical' | 'releaseDate' | 'none';
    isShowPicture?: boolean;
    view?: 'hp' | 'pck' | 'search';
}

export default function ListCards({ 
    className,
    pckList,
    sortFunction = 'alphabetical',
    isShowPicture = false,
    view = 'hp'
}: ListCardsProps) {

    const formatDate = (dateString: string) => {
        const date = new Date(dateString);
    
        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-based
        const day = date.getDate().toString().padStart(2, '0');
    
        return `${year}-${month}-${day}`;
    };

    const createCardsList = () => {
        let allPcksList: CardWithBasicInfo[] = [];

        if (pckList.products && pckList.products.length > 0) {

            const filtersPlatform = pckFilters.find(filter => filter.id === 'platform');
            const findPlatform = (platformId: string) => {
                const platformName = filtersPlatform?.options.find(platform => platform.value === platformId);
                if (platformName) return platformName.label;
                return '' ;
            }

            const productsFiltered: CardWithBasicInfo[] = pckList.products.flatMap(productId =>
                allProductsWithBasicInfo().filter(item => item.productId === productId)
            ).map(product => ({
                cardId: product.productId,
                categories: product.categories,
                description: product.description,
                imageName: product.imageName,
                name: product.name,
                platform: findPlatform(product.filters.platform),
                releaseDate: product.latestReleaseDate,
                type: 'packages'
            }));
            allPcksList = allPcksList.concat(productsFiltered);
        }
    
        if (pckList.patches && pckList.patches.length > 0) {
            const patchesFiltered: CardWithBasicInfo[] = pckList.patches.flatMap(patchId =>
                Patches.filter(item => item.patchId === patchId)
            ).map(patch => ({
                cardId: patch.patchId,
                description: patch.title,
                name: patch.patchId,
                productName: patch.name,
                productVersion: patch.versions,
                releaseDate: formatDate(patch.date),
                type: 'patches'
            }));

            allPcksList = allPcksList.concat(patchesFiltered);
        }

        if (pckList.searchList && pckList.searchList.length > 0) {
            pckList.searchList.map(pckItem => {
                if (pckItem.type === 'patch') {
                    const patch = Patches.find(patch => patch.patchId === pckItem.id);
                    if(!patch) return;
                    allPcksList.push({
                        cardId: patch.patchId,
                        description: patch.title,
                        name: patch.patchId,
                        productName: patch.name,
                        productVersion: patch.versions,
                        releaseDate: formatDate(patch.date),
                        score: pckItem.score,
                        type: 'patches'
                    });

                } else if (pckItem.type === 'product') {
                    const filtersPlatform = pckFilters.find(filter => filter.id === 'platform');
                    const findPlatform = (platformId: string) => {
                        const platformName = filtersPlatform?.options.find(platform => platform.value === platformId);
                        if (platformName) return platformName.label;
                        return '' ;
                    }

                    const product = allProductsWithBasicInfo().find(pt => pt.productId === pckItem.id);
                    if(!product) return;
                    allPcksList.push({
                        cardId: product.productId,
                        categories: product.categories,
                        description: product.description,
                        imageName: product.imageName,
                        name: product.name,
                        platform: findPlatform(product.filters.platform),
                        releaseDate: product.latestReleaseDate,
                        score: pckItem.score,
                        type: 'packages'
                    })
                }
            })
        }

        return allPcksList;
    }

    const sortPackages = (sortBy: 'alphabetical' | 'releaseDate' | 'none') => {
        return createCardsList().sort((a: any, b: any) => {
            if (sortBy === 'releaseDate') {
                return a.name.localeCompare(b.latestReleaseDate);
            } else if (sortBy === 'alphabetical') {
                return a.name.localeCompare(b.name);
            } else {
                return createCardsList()
            }
        });
    };


    return (
        <div className={cn('grid grid-cols-1 gap-5 3xl:gap-8 4xl:gap-9', className)}>

            {
                sortPackages(sortFunction) && sortPackages(sortFunction).length > 0 ? (
                    sortPackages(sortFunction).map((pck: CardWithBasicInfo, key: number) => (
                        <IndivCard
                            pck={pck}
                            key={key}
                            isShowPicture={isShowPicture}
                            view={view}
                            btnInfo={
                                {
                                    title: pck.type === 'patches' ? 'Get Patches' : 'Get Packages',
                                    link: `/${pck.type}/${pck.cardId}`
                                }
                            }
                        />
                    ))
                ) : (
                    <p>No packages found!</p>
                )
            }
        </div>
        
    );
}
