import React  from 'react';
import { Route, Routes } from 'react-router-dom';
import { Home } from 'pages/home';
import { PackagesId } from 'pages/package';
import { PatchId } from 'pages/patch';
import AllPackagesPage from 'pages/all-packages';
import NotFound from 'pages/404';

export const AppRouter = () => {
    return (
        <Routes>
            <Route path='/' element={<Home />} />
            <Route path='/packages' element={<AllPackagesPage />} />
            <Route
                path='/packages/:packageId'
                element={< PackagesId />}
            />
            <Route
                path='/patches/:patchId'
                element={< PatchId />}
            />
            <Route
                path='/not-found'
                element={< NotFound message={'Page not found'} />}
            />
            <Route
                path='*'
                element={< NotFound message={'Page not found'} />}
            />
        </Routes>
    );
};
