/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useState } from 'react';
import { useSearchParams, useLocation  } from 'react-router-dom';
import { Title } from 'components/ui/text';
import ClearFilters from 'layouts/filters-query-params/filters-clear';
import RemoveSidebarFilters from 'layouts/filters-query-params/filters-remove-sidebar';
import { ActionIcon } from 'components/ui/action-icon';
import { useDrawer } from 'components/drawer-views/use-drawer';
import cn from 'utils/class-names';
import FaXmarkIcon from 'assets/fa-xmark-icon';
import { Radio } from 'components/ui/radio';
import FilterCircleXMark from 'assets/fa-filter-circle-xmark';
import { Tooltip } from 'components/ui/tooltip';

export function FilterList(
    { filterElements, type }: {filterElements: any, type?: string,}
) {
    
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [searchParams, setSearchParams] = useSearchParams();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [queryParamsState, setQueryParamsState] = useState(Object.fromEntries(queryParams.entries()));
    const { closeDrawer } = useDrawer();

    function handleCheckboxChange(event: any, filterId: string, value: string) {
        const isClicked = event?.target?.checked;
        clearFilters();

        setSearchParams((prevParams) => {
            const newParams = new URLSearchParams(prevParams);
            if (isClicked) {
                newParams.set(filterId, value);
            } else {
                newParams.delete(filterId);
            }
        
            return newParams;
        });

    }

    function clearFilters() {
        Object.keys(queryParamsState).map(filterName => {
            setSearchParams((params) => {
                params.delete(filterName);
                return params;
            });
        })
    }

    useEffect(() => {
        setQueryParamsState(Object.fromEntries(queryParams.entries()));
    }, [location.search]);

    return (
        <div className={cn({
            'bg-white': type === 'sm',
            'space-y-4 dark:bg-blue-light': true
        })}>
            {type === 'sm' && 
                <div className='flex items-center justify-between border-b border-gray-200 px-5 py-3.5'>
                    <Title as='h5' className='font-semibold'>
                        Filters
                    </Title>
                    <ActionIcon
                        variant='outline'
                        onClick={() => closeDrawer()}
                        className='border-0 p-0'
                    >
                        <FaXmarkIcon themeColor='dark:text-white text-blue-light'/>
                    </ActionIcon>
                </div>
            }
            {filterElements?.filter((section: any) => section.id !== 'platform').map((section: any, index: number) => (
                <div key={section.name} className={cn({
                    'pl-5': type === 'sm'
                })}>
                    <div className='flex items-center justify-between'>
                        <Title as='h3' className='font-semibold '>
                            {section.name}
                        </Title>
                        {index === 0 && type !== 'sm' &&
                            <Tooltip
                                content={'Clear Filters'}
                                placement='top' size='sm'
                                showArrow={false}
                                className='bg-blue-light text-white dark:bg-white dark:text-blue-light'
                                arrowClassName='bg-blue-light text-white dark:bg-white dark:text-blue-light'
                            >
                                <ActionIcon variant='text' className='mr-4' size='sm' onClick={() => clearFilters()}>
                                    <FilterCircleXMark themeColor='dark:text-white text-blue-light'/>
                                </ActionIcon>
                            </Tooltip>
                        }
                    </div>
                    {
                        section.options.map((option: any, optionIdx: any) => (
                            <Radio
                                id={`filter-${option.value}-${optionIdx}`}
                                key={`${option.label}-key`}
                                label={option.label}
                                labelClassName='text-sm'
                                size='sm'
                                checked={queryParams.get(section.id) === option.value ? true : false}
                                onChange={(event) => handleCheckboxChange(event, `${section.id}`, option.value)}
                                className='[&>label>span]:font-medium pt-4 dark:text-white'
                                inputClassName='checked:!bg-white checked:text-color checked:!border-blue-sentry focus:ring-blue-sentry focus:ring-offset-blue-sentry-0 checked:!bg-blue-dark dark:checked:!border-blue-sentry dark:focus:ring-blue-sentry dark:focus:ring-offset-blue-sentry-0'
                            />
                        ))
                    }
                    <div className='mt-5 border-b border-solid border-blue-dark border-opacity-20 dark:border-opacity-60 mr-2'></div>
                </div>
            ))}

            { type === 'sm' && 
                <RemoveSidebarFilters 
                    className={cn({
                        'ml-5': type === 'sm'
                    })}
                ></RemoveSidebarFilters>
            }

            <ClearFilters 
                className={cn({
                    'ml-5': type === 'sm'
                })}
            >
            </ClearFilters>
        </div>
    )
}
