import { Link as RouterLink  } from 'react-router-dom';

export interface LinkProps {
    children?: any;
    href: string;
    className?: string;
    label?: string;
    skipClientSideRouting?: boolean;
}

export function Link(props: LinkProps) {

    const isSkipClientSideRouting = props.href.startsWith('https') || props.skipClientSideRouting === true;
    let isNewTabRequired = false;
    if (props.href.startsWith('https')) {
        isNewTabRequired = true;
    }

    return (
        <>
            {
                isSkipClientSideRouting ? (
                    <RouterLink to={props.href} target={isNewTabRequired ? '_blank' : undefined} reloadDocument className={props?.className} aria-label={props.label}>
                        {props?.children}
                    </RouterLink>
                ) : (
                    <RouterLink to={props.href} className={props?.className} aria-label={props.label}>
                        {props?.children}
                    </RouterLink>
                )
            }
        </>
    );
}
