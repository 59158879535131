import { useEffect, useState } from 'react';
import { Button } from 'components/ui/button';
import { useSearchParams, useLocation  } from 'react-router-dom';

export default function ClearFilters({className}: {className: string}) {

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [searchParams, setSearchParams] = useSearchParams();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const [queryParamsState, setQueryParamsState] = useState(Object.fromEntries(queryParams.entries()));

    useEffect(() => {
        setQueryParamsState(Object.fromEntries(queryParams.entries()));
    }, [location.search]);

    function clearFilters() {
        Object.keys(queryParamsState).map(filterName => {
            setSearchParams((params) => {
                params.delete(filterName);
                return params;
            });
        })
    }

    return (
        <Button
            size='sm'
            variant='solid'
            className={`${className} text-white signin-button bg-blue-dark uppercase dark:text-blue-dark dark:bg-white hover:bg-blue-dark dark:hover:bg-white`}
            onClick={() => clearFilters()}
        >
            <div className='mr-2'>
                Clear filters
            </div>
        </Button>
    )
}